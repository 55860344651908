const useBreakpoints = () => {
  const breakpoints: Record<Breakpoint, number> = {
    base: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1180,
  };

  const currentWidth = ref(0);
  const currentBreakpoint = ref<Breakpoint>("sm");

  const onResize = () => {
    currentWidth.value = window.innerWidth;

    const width = currentWidth.value;

    if (width >= breakpoints.xl) {
      currentBreakpoint.value = "xl";
    } else if (width >= breakpoints.lg) {
      currentBreakpoint.value = "lg";
    } else if (width >= breakpoints.md) {
      currentBreakpoint.value = "md";
    } else if (width >= breakpoints.sm) {
      currentBreakpoint.value = "sm";
    } else {
      currentBreakpoint.value = "base";
    }
  };

  watch(currentWidth, onResize);

  if (process.client) {
    onMounted(() => {
      currentWidth.value = window.innerWidth;
      window.addEventListener("resize", onResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
    });
  }

  return {
    breakpoints,
    currentWidth,
    currentBreakpoint,
  };
};

export default useBreakpoints;
