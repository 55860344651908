<template>
  <Slider v-if="data?.items.length" :breakpoints="{ sm: 2, md: 3 }" :total="data.items.length" per-navigation="view" :ui="ui">
    <div v-for="collection in data.items" :key="collection.id" class="group flex flex-col">
      <ContentExperienceCard :collection="collection" class="grow" />
      <!-- <div class="mx-auto mt-3 space-x-6 opacity-0 transition-opacity group-hover:opacity-100">
              <Button as="a" :to="collection.url" target="_blank" prepend-icon="eye" class="text-supplement hover:text-primary">Preview</Button>
              <Button variant="link" prepend-icon="check" class="text-supplement hover:text-primary">Accept</Button>
            </div> -->
    </div>
  </Slider>
</template>

<script setup lang="ts">
import type { SliderProps } from "./slider/Slider.vue";

interface ContentExperienceSliderProps {
  endpoint: string;
  params?: Record<string, unknown>;
  ui?: SliderProps["ui"];
}

const props = defineProps<ContentExperienceSliderProps>();

const { data, error } = await useAsyncData("collections", () =>
  $larafetch<LaravelPaginatedResponse<ContentExperience[]>>(`/${props.endpoint}`, { params: props.params })
);

if (!data.value || error.value) {
  throw createError({ statusMessage: "Failed to fetch content experiences" });
}
</script>
